import React from "react"
import { kantreeForms } from "../../data/links.data"
import Layout from "../components/layout"
import SEO from "../components/seo"

import logo from "../images/logo.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Zubilinks" />
    <header>
      <div className="logo_wrapper">
        <img src={logo} alt="logo zubilabs" />
      </div>
      <div>
        <h1>Formularios de petición de tareas EECC </h1>
      </div>
    </header>

    <section className="intro_text">
      <p>¡¡Hola!! Bienvenido a la página de solicitud de tareas a Equipos Compartidos de Zubi Labs. Si estás aquí es porque necesitas que desde Equipos Compartidos te echemos una mano, ¿verdad?. ¡¡Vamos a ello!!</p>
      <ol>
        <li><b>Selecciona primero el formulario</b> del Equipo Compartido al que quieres involucrar en tu proyecto o tarea.</li>
        <li>Para poder ayudarte, por favor, una vez dentro, <b>completa todos los campos</b> del mismo. Recuerda que cuanta más información nos facilites, mejor podremos ayudarte.</li>
        <li>Una vez recibamos tu petición, <b>nos pondremos en contacto contigo</b> para recabar más detalles y poder darte una fecha aproximada de resolución de tu petición.</li>
      </ol>
      <p>Muchas gracias por ayudarnos a ayudarte. ¡¡Pasa muy buen día!!
      </p>
    </section>
    <secction className="links">
      {kantreeForms.map((item, i) => (
        <a href={item.link} target="_blank" rel="noreferrer">
          <div className="link_item" key={i}>
            <div className="icon_wrapper">
              <img src={`/img/${item.img}-logo.svg`} alt="" />
            </div>
            <p>{item.label}</p>
          </div>
        </a>
      ))}
    </secction>

    <section className="intro_text">
    </section>
  </Layout>
)

export default IndexPage
