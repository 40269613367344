const baseURL = "https://odoo.zubilabs.net/"

export const kantreeForms = [
  {
    link: baseURL + "markform",
    type: "marketing",
    label: "Marketing y comunicación",
    img: "marketing",
  },
  // {
  //   link: baseURL + "",
  //   type: "diseno",
  //   label: "Diseño",
  //   img: "diseno",
  // },
  {
    link: baseURL + "techform",
    type: "tech",
    label: "Tecnologia",
    img: "tech",
  },
  {
    link: baseURL + "finform",
    type: "financial",
    label: "Financiero",
    img: "financial",
  },
  {
    link: baseURL + "peopleform",
    type: "people",
    label: "People",
    img: "people",
  },
  {
    link: baseURL + "pfform",
    type: "financiacion-publica",
    label: "Financiación Pública",
    img: "public",
  },
  {
    link: baseURL + "legalform",
    type: "legal",
    label: "Asesoría Legal",
    img: "legal",
  },
  {
    link: baseURL + "investform",
    type: "investor",
    label: "Investor Relations",
    img: "investor",
  },
  {
    link: baseURL + "opsform",
    type: "desarrollo",
    label: "Desarrollo de Negocio",
    img: "desarrollo",
  },
]
